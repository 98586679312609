@import "src/styles/mixins";
@import "src/styles/shortcuts";

.NotFoundedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 100%;
  min-height: 456px;
  border-radius: 6px;
  padding: 16px 32px;
  color: $blackText;
  cursor: pointer;
  margin-right: 16px;

  &:hover {
    background-color: $white;
    color: $red;
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  @include beMobile() {
    margin-right: 5px;
  }

  &_Block {
    display: flex;
    flex-direction: column;

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 125%;
      text-align: center;
      letter-spacing: -.01em;
      margin-bottom: 24px;
      transition: .2s ease-in-out;
    }

    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 125%;
      text-align: center;
      color: $headerLink;
    }

    &_Image {
      background-color: $iconGrayBack;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto auto 16px;
      padding: 15px;

      & > img {
      }
    }
  }
}
