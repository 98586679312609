@import "src/styles/mixins.scss";
@import "src/styles/shortcuts";

.mainDonationCard {
  background: $white;
  border-radius: 6px;
  min-height: 383px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .2s ease-in-out;
  cursor: pointer;
  padding: 16px;
  margin-right: 11px;
  box-shadow: 0 4px 16px rgba(37, 36, 36, 0.05);

  &:hover {
    box-shadow: 0 4px 24px rgba(37, 36, 36, 0.1);
  }

  @include beMobile() {
    padding: 12px;
    margin-right: 0;
  }

  &_header {
    //margin-top: 15px;
    //padding: 0 15px 8px;

    @include beMobile() {
      //padding: 0 24px 8px;
    }

    &_title {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 130%;
      color: $title;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      min-height: 56px;
    }
  }

  &_Needs {
    width: 100%;
    background: linear-gradient(96.4deg, $red 0%, $textFiol 100%);
    margin-top: auto;
    margin-bottom: -10px;
    border-radius: 10px 10px 0 0;

    &_Block {
      width: 100%;
      padding: 16px 24px 24px 24px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      &_Span {
        background: $white;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $blackText;
        cursor: pointer;
      }

      &_SpanDeactive {
        background: $white;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $blackText;
        cursor: pointer;
        opacity: .2;
      }
    }
  }

  &_status {
    position: relative;
    height: 49px;
    background: linear-gradient(96.4deg, $red 0%, $textFiol 100%);
    width: 100%;
    border-radius: 6px 6px 0 0;

    &_info {
      position: absolute;
      bottom: -8px;
      left: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 49px;
      color: $white;
    }

    &_text {
      position: absolute;
      top: 8px;
      right: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      color: $white;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $iconGrayBack;
    padding: 0 24px 24px;
    border-radius: 10px 10px 6px 6px;
    z-index: 1;
    background-color: white;
    min-height: 120px;

    &_address {
      display: flex;
      align-items: center;
      margin-top: 12px;

      &:first-child {
        margin-top: 24px;
      }

      &_icon {
        display: block;
        width: 25px;
        height: 24px;
        margin-right: 5px;
      }

      &_text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
      }
    }
  }
}

.MoreCartButton div a {
  background: #fff;
  color: $red;
  border: 1px solid $red;
  border-radius: 8px;
  z-index: 10;

  &:hover {
    background: #fff;
    color: $red;
    border: 1px solid $red;
    border-radius: 8px;
  }
}
