@import "src/styles/mixins";
@import "src/styles/shortcuts";

.Modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 1.5rem !important;

  &_Close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
  }

  &_Head {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    & > h3 {
      font-weight: 500;
      padding: 0;
      font-style: normal;
      font-size: 16px;
      color: $blackText;
      margin: 0 0 16px;
    }

    &_Planed {
      display: flex;
      flex-direction: column;

      &_Date {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: $blackText;
        margin-bottom: 8px;
      }

      &_Title {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 125%;
        color: $blackText;
      }

      &_Address {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: $headerLink;
      }
    }
  }

  &_Body {
    display: flex;
    align-items: center;
    width: 100%;
    @include beMobile() {
      flex-direction: column;
      &_Decline {
        margin-bottom: 8px;
        width: 100%;
        margin-right: 0!important;
      }

      &_Accept {
        margin-right: 0!important;
      }
    }

    &_Decline {
      outline: none !important;
      -webkit-appearance: none;
      margin-right: 8px;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.15;
      background: transparent;
      color: black;
      padding: 12px 24px;
      transition: .2s ease-in-out;
      border: 1px solid $iconGrayBack;
      border-radius: 4px;
      white-space: nowrap;

      &:hover {
        border: 1px solid $red;
        color: black;
      }
    }

    &_Accept {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 24px;
      color: $white;
      border-radius: 4px;
      outline: none !important;
      border: none;
      -webkit-appearance: none;
      margin-right: 8px;
      cursor: pointer;
      width: 100%;
      @include BTN($red, $redActive);

      & > img {
        animation-name: slowlyRotate;
        animation-duration: 2s;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.UserImage {
  width: 44px;
  height: 44px;

  & > img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid #F6F7F8;
  }

  &_Pseudo {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: pink;

    & > span {
      color: $white;
      font-size: 18px;
    }
  }
}

.DetailBloodStationPlan {
  display: flex;
  flex-direction: column;

  &_Button {
    display: flex;
    width: 100%;
    margin-top: 8px;

    & > a, button {
      @include BTN($red, $redActive);
      width: 100%;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $white;
      padding-top: 18px;
      padding-bottom: 17px;
      border-radius: 8px;

      & > img {
        animation-name: slowlyRotate;
        animation-duration: 2s;
        width: 16px;
        height: 16px;
      }
    }
  }

  &_Planned {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &_Text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      color: $headerLink;
      margin-right: 16px;
    }

    &_Images {
      display: flex;
      flex-wrap: nowrap;
      margin-left: auto;

      & > div {
        &:not(:first-child) {
          margin-left: -20px;
        }
      }
    }
  }
}
