@import "src/styles/mixins";
@import "src/styles/shortcuts";
@import "./src/styles/fonts";

.DetailBloodStationNeeds {
  margin-bottom: 12px;
  &_City {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $BaG_black;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    margin-top: 8px;
  }

  &_Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &_Info {
      display: flex;
      flex-direction: column;

      & > h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: $title;
        margin: 0 0 8px;
        padding: 0;
      }

      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $headerLink;
      }
    }

    &_Data {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      &_Green {
        display: flex;
        align-items: center;
        margin-left: auto;

        & > div {
          margin-left: 8px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $btnGreen;
        }

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $headerLink;
      }

      &_Red {
        display: flex;
        align-items: center;
        margin-left: auto;

        & > div {
          margin-left: 8px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: $red;
        }

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $headerLink;
      }
    }
  }

  &_Body {
    &_NoNeeds {
      background-color: $white;
      border-radius: 8px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > div {
        background: $iconGrayBack;
        padding: 15px;
        border-radius: 50%;
        margin: auto;

        & > img {
          width: 34px;
          height: 34px;
        }
      }

      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: $title;
        margin-top: 8px;
      }
    }

    &_Plats {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    &_GroupSelect {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      & > span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $blackText;
        margin-bottom: 8px;
      }

      & > p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        color: $headerLink;
      }
    }
  }
}

.Container {
  cursor: pointer;
  flex: 1 0 20%;
  margin: 5px;
  width: 80px;
  height: 64px;
  padding: 8px;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: .2s ease-in-out;
  @include toIphoneSix() {
    width: 68px;
    height: 52px;
    padding: 4px;
  }
  @include desktop() {
    width: 84px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    & > span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.3;
      width: 100%;
      text-align: center;
      margin-top: 2px;
      @include toIphoneSix() {
        font-size: 14px;
      }
      @include desktop() {
        font-size: 16px;
      }
    }
  }
}

@keyframes changeColorRed {
  0% {
    background-color: rgba(246, 62, 62, 0.1);
  }
  50% {
    background-color: rgba(246, 62, 62, 0.8);
  }
  100% {
    background-color: rgba(246, 62, 62, 0.2);
  }
}

@keyframes changeColorGreen {
  0% {
    background-color: rgba(39, 193, 117, 0.1);
  }
  50% {
    background-color: rgba(39, 193, 117, 0.8);
  }
  100% {
    background-color: rgba(39, 193, 117, 0.25);
  }
}


.NeedsItem {
  &Red {
    @extend .Container;
    background-color: rgba(246, 62, 62, 0.1);
    border: 2px solid rgba(247, 62, 62, 0.4);
    color: $red;

    &:hover, &:focus, &:active {
      border: 2px solid $red;
    }

    &[data-focused="true"] {
      border: 2px solid $red;
      background-color: rgba(246, 62, 62, 0.2);
      animation-name: changeColorRed;
      animation-duration: .2s;
    }
  }

  &Green {
    @extend .Container;
    background-color: rgba(39, 193, 117, 0.1);
    border: 2px solid rgba(39, 193, 117, 0.4);
    color: $btnGreen;

    &:hover, &:focus, &:active {
      border: 2px solid $btnGreen;
    }

    &[data-focused="true"] {
      border: 2px solid $btnGreen;
      background-color: rgba(39, 193, 117, 0.25);
      animation-name: changeColorGreen;
      animation-duration: .2s;
    }
  }
}
